import { useRef, useEffect, useState, useCallback } from "react"
import { toast } from "../../utils/toast"
import { RED } from "../../utils/colors"
import { StatusChamada, OpenTokHandlersHook, MsgHook, MsgType } from "./types"
import { analyticsEvt, AnalyticsHook } from "../useAnalytics"

const videoEvents = {
    otrnError: 'Ocorreu um erro no App. Tente novamente.',
    archiveStarted: 'Este atendimento está sendo gravado',
    archiveStopped: 'A gravação do atendimento foi pausada',
    connectionCreated: 'O parceiro está conectado',
    connectionDestroyed: 'Esta sessão foi encerrada inesperadamente. Tente novamente.',
    sessionDisconnected: 'Esta sessão foi encerrada. Tente novamente.',
    sessionConnected: 'Você está conectado!',
    sessionReconnected: 'Você está conectado novamente!',
    sessionReconnecting: 'Reconectando...',
    streamCreated: 'O parceiro está transmitindo',
    videoDisabled: 'O vídeo foi desabilitado',
    videoEnabled: 'O vídeo foi habilitado',
    videoDisableWarning: 'A transmissão de vídeo foi desabilitada',
    videoDisableWarningLifted: 'A transmissão de vídeo foi retomada',
    publisherStreamCreated: 'Você está transmitindo...',
    publisherStreamDestroyed: 'Você parou de transmitir...',
    tokenExpired: 'O prazo desta sessão expirou',
    error: 'Ocorreu um erro na conexão',
    streamDestroyed: 'O parceiro parou de transmitir'
}

const getError = (e: string | {name: string}): {type: string, msg: string} => {
    const errorName = typeof e == 'string' ? e : e.name
    if(typeof errorName == 'string'){
        let error = '';
        switch(errorName){
            //Erros do OT.initPublisher
            case 'OT_HARDWARE_UNAVAILABLE': error = "A câmera ou microfone estão sendo usados por outra aplicação."; break;
            // case 'OT_INVALID_PARAMETER': error = "One or more parameters was not valid or not provided"; break;
            case 'OT_MEDIA_ERR_ABORTED': error = "A transmissão de vídeo foi abortada. Verifique se os dispositivos de áudio e vídeo não estão sendo utilizados por outra aplicação."; break;
            case 'OT_MEDIA_ERR_DECODE': error = "Ocorreu um erro na decodificação do vídeo."; break;
            case 'OT_MEDIA_ERR_NETWORK': error = "Verifique a qualidade da sua conexão de internet."; break;
            // case 'OT_MEDIA_ERR_SRC_NOT_SUPPORTED': error = "The stream has been detected to be not suitable for playback."; break;
            case 'OT_NOT_SUPPORTED': error = "Este navegador ou dispositivo não é compatível com esta tecnologia de vídeo conferência."; break;
            case 'OT_NO_DEVICES_FOUND': error = "Nenhum dispositivo de áudio e vídeo foi encontrado."; break;
            case 'OT_NO_VALID_CONSTRAINTS': error = "Seu aúdio e vídeo estão desabilitado. É necessário habilitar ao menos um deles."; break;
            case 'OT_SCREEN_SHARING_NOT_SUPPORTED': error = "Este navegador não suporta compartilhamento de tela."; break;
            // case 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED': error = "Screen-sharing support in this browser requires an extension, but one has not been registered."; break;
            // case 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED': error = "Screen-sharing support in this browser requires an extension, but the extension is not installed."; break;
            // Erros do Session.connect
            case 'OT_AUTHENTICATION_ERROR': error = "O seu acesso a esta sessão expirou."; break;
            // case 'OT_BADLY_FORMED_RESPONSE': error = "The JSON response from the OpenTok server was badly formed."; break;
            case 'OT_CONNECT_FAILED': error = "Não foi possível conectar à sessão."; break;
            case 'OT_CONNECTION_LIMIT_EXCEEDED': error = "O limite de conexões simultâneas foi atingido para esta sessão."; break;
            // case 'OT_EMPTY_RESPONSE_BODY': error = "Received an unexpected empty response from the OpenTok server."; break;
            case 'OT_INVALID_SESSION_ID': error = "O ID da sessão não é válido."; break;
            // case 'OT_INVALID_PARAMETER': error = "One or more parameters was not valid or not provided."; break;
            case 'OT_NOT_CONNECTED': error = "Você está offline."; break;
            // case 'OT_TERMS_OF_SERVICE_FAILURE': error = "Couldn't connect due to a terms of service violation."; break;
            case 'OT_INVALID_HTTP_STATUS': error = "Ocorreu um erro durante a requisição."; break;
            // case 'OT_XDOMAIN_OR_PARSING_ERROR': error = "There was a cross domain error or the server responded with invalid JSON."; break;
            //Erros de Session.publish
            case 'OT_CHROME_MICROPHONE_ACQUISITION_ERROR': error = "O Chrome apresentou um erro ao acessar o microfone."; break;
            case 'OT_CONSTRAINTS_NOT_SATISFIED': error = "Não foi possível acessar os dispositivos de mídia."; break;
            case 'OT_CREATE_PEER_CONNECTION_FAILED': error = "Ocorreu uma falha ao conectar à sessão."; break;
            case 'OT_ICE_WORKFLOW_FAILED': error = "Ocorreu um erro ao estabelecer a conexão."; break;
            case 'OT_PERMISSION_DENIED': error = "Você não pode publicar."; break;
            case 'OT_SET_REMOTE_DESCRIPTION_FAILED': error = "Não foi possível estabelecer a conexão."; break;
            case 'OT_STREAM_CREATE_FAILED': error = "Não foi possível iniciar a transmissão."; break;
            case 'OT_TIMEOUT': error = "As tentativas de comunicação foram excedidas. verifique sua conexão com a internet e tente novamente."; break;
            case 'OT_USER_MEDIA_ACCESS_DENIED': error = "Você precisa autorizar o acesso ao microfone e à câmera."; break;
            case 'OT_UNEXPECTED_SERVER_RESPONSE': error = "Ocorreu um erro inesperado no servidor de vídeo conferência."; break;
            // Erros de Session.subscribe
            case 'OT_DISCONNECTED': error = "Você não está conectado à uma sessão válida."; break;
            case 'OT_STREAM_DESTROYED': error = "A sessão a qual você se conectou foi encerrada."; break;
            case 'OT_STREAM_LIMIT_EXCEEDED': error = "O número de transmissões foi excedido nesta sessão."; break;
            case 'OT_STREAM_NOT_FOUND': error = "Não foi possível encontrar a transmissão."; break;
            //Outros
            case 'OT_SOCKET_CLOSE_TIMEOUT': error = "O tempo de tentativas de conexão foi excedido."; break;
            case 'OT_SOCKET_CLOSE_FALLBACK_CODE': error = "O tempo de tentativas de conexão foi excedido."; break;
        }

        return {type: errorName, msg: error}
    }else{
        return {type: '', msg: ''}
    }
}


export const useOpenTokHandlers = (status: StatusChamada, onEncerrar: Function, msgHook: MsgHook,  analyticsHook: AnalyticsHook): OpenTokHandlersHook => {
    const [error, setError] = useState({type: '', msg: ''})
    const [connIdParceiro, setConnIdParceiro] = useState('')
    function onAlert(e: string){
        toast((videoEvents as any)[e], RED)
    }

    useEffect(() => {
        if (status == 'desligada') {
            msgHook.onReset()
        }
    }, [status])


    function onChangeFeedBack(e: string) {
        const msg = (videoEvents as any)[e]
        msgHook.onAddMsg(msg, 'bot')
    }

    const onError = useCallback((status: StatusChamada) => {
        return (e: any) => {
            onEncerrar()
            const erroPrevisto = getError(e)
            console.log(e)
            if(Boolean(erroPrevisto.type)){
                analyticsHook.logEvent(analyticsEvt.erro_tokbox_previsto, {error: erroPrevisto.type})
                setError(erroPrevisto)
            }else{
                analyticsHook.logEvent(analyticsEvt.erro_tokbox_previsto, {error: JSON.stringify(erroPrevisto)})
            }
    }
    }, [])

    function onCloseError(){
        analyticsHook.logEvent(analyticsEvt.erro_tokbox_previsto_notado, {error: error.type})
        setError({type: '', msg: ''})
    }



    const onOTRNError = useCallback( (e: any) => {
            analyticsHook.logEvent(analyticsEvt.tokbox_otrnerror, {error: e})
            onEncerrar()
            onAlert('otrnError')
    }, [])    



    const sessionEvents = {
        error: onError(status),
        otrnError: onOTRNError,
        signal: useCallback((e: any) => {
            msgHook.onAddMsg(e.data, 'other')
        }, []),
        //(Object) - Sent when an archive recording of a session starts. If you connect to a session in which recording is already in progress, this message is sent when you connect.
        archiveStarted: useCallback((e: any) => {
            onChangeFeedBack('archiveStarted')
            //console.log(e)
        }, []),
        //(String) - Sent when an archive recording of a session stops.
        archiveStopped: useCallback((e: any) => {
            onChangeFeedBack('archiveStopped')
            //console.log(e)
        }, []),

        //(Object) - Sent when another client connects to the session. The connection object represents the client’s connection.
        connectionCreated: useCallback((e: any) => {
            //console.log(e)
            // onChangeFeedBack('connectionCreated')
        }, []),

        //(Object) - Sent when another client disconnects from the session. The connection object represents the connection that the client had to the session.
        connectionDestroyed: useCallback((e: any) => {
            //console.log(e);
            // onEncerrar()
            if (e.reason && e.reason != 'clientDisconnected') {
                onAlert('connectionDestroyed')
            }
        }, []),


        //() - Sent when the client connects to the session.
        sessionConnected: useCallback((e: any) => {
            //console.log('sessionConnected', e)
            onChangeFeedBack('sessionConnected')
        }, []),

        //() - Sent when the client disconnects from the session.
        /**
         * Este evento era chamado sempre que havia desconexão, no entanto, após a modificação deste commit (remoção dos binds de estado do useEffect), ele parou de ser chamado.
         * Ele era chamado multiplas vezes, dependendo do número de desconexões. Exemplo: Na primeira desconexão era chamado uma vez. Na segunda duas, e por aí vai.
         * Tudo leva a crer que era devido ao bind com o método onEncerrar. Contudo, após essa alteração, apenas o streamDestroyed é chamado, mas tudo funciona bem
         */
        sessionDisconnected: useCallback((e: any) => {
            //console.log('sessionDisconnected', e);
            // onEncerrar()
            if (e.reason && e.reason != 'clientDisconnected') {
                onAlert('sessionDisconnected')
            }
        }, []),

        //() - Sent when the local client has reconnected to the OpenTok session after its network connection was lost temporarily.
        sessionReconnected: useCallback((e: any) => {
            //console.log('sessionReconnected', e)
            onChangeFeedBack('sessionReconnected')
        }, []),

        //() - Sent when the local client has lost its connection to an OpenTok session and is trying to reconnect. This results from a loss in network connectivity. If the client can reconnect to the session, the sessionReconnected message is sent. Otherwise, if the client cannot reconnect, the sessionDisconnected message is sent.
        sessionReconnecting: useCallback((e: any) => {
            //console.log('sessionReconnecting', e)
            onChangeFeedBack('sessionReconnecting')
        }, []),

        //(Object) - Sent when a message is received in the session.
        //signal: (e: any) => console.log(e),

        //(Object) - Sent when a new stream is created in this session.
        streamCreated: useCallback((e: any) => {
            onChangeFeedBack('streamCreated')  
            // connData.onChangeOther(e)     
            if(e.stream){
                setConnIdParceiro(e.stream.connection.id)
            }else if(e.connection){
                setConnIdParceiro(e.connection.connectionId)
            }
        }, []),

        //(Object) - Sent when a stream is no longer published to the session.
        streamDestroyed: useCallback((e: any) => {
            onChangeFeedBack('streamDestroyed')
            setConnIdParceiro('')
            // if (e.reason !== "clientDisconnected") {
            //     messageContext.info(formatMessage('A chamada foi encerrada devido às condições de rede'))
            //     onEncerrar(false)
            // }
        }, []),

        //(Object) - Sent when a stream has started or stopped publishing audio or video or if the video dimensions of the stream have changed.            
        //streamPropertyChanged: (e: any) => console.log(e),
    }


    const subscriberEvents = {
        //(String) — Sent on a regular interval with the recent representative audio level.
        //audioLevel: (e: any) => console.log(e),

        //(Object) — Sent periodically to report audio statistics for the subscriber.
        //audioNetworkStats: (e: any) => console.log(e),

        //() — Sent when the subscriber successfully connects to the stream.
        // connected: useCallback((e: any) => {
        //     if(e.target){
        //         setConnIdParceiro(e.target.stream.connection.id)
        //     }else if(e.stream){
        //         setConnIdParceiro(e.stream.connection.connectionId)
        //     }
        // }, []),

        //() — Called when the subscriber’s stream has been interrupted.
        // disconnected: useCallback((e: any) => {
        //     setConnIdParceiro('')
        //     console.log('disconnected')
        // }, []),

        //(Object) — Sent if the subscriber fails to connect to its stream.
        error: onError(status),

        //(Object) — Sent if there is an error with the communication between the native subscriber instance and the JS component.
        otrnError: onOTRNError,

        //() - Sent when a frame of video has been decoded. Although the subscriber will connect in a relatively short time, video can take more time to synchronize. This message is sent after the connected message is sent.
        //videoDataReceived: (e: any) => console.log('videoDataReceived', e),

        //(String) — This message is sent when the subscriber stops receiving video. Check the reason parameter for the reason why the video stopped.
        videoDisabled: useCallback((e: any) => {
            analyticsHook.logEvent(analyticsEvt.tokbox_videoDisabled)
            onChangeFeedBack('videoDisabled')
        }, []),

        //(String) - This message is sent when the subscriber’s video stream starts (when there previously was no video) or resumes (after video was disabled). Check the reason parameter for the reason why the video started (or resumed).
        videoEnabled: useCallback((e: any) => {
            onChangeFeedBack('videoEnabled')
            //console.log(e)
        }, []),

        //() - This message is sent when the OpenTok Media Router determines that the stream quality has degraded and the video will be disabled if the quality degrades further. If the quality degrades further, the subscriber disables the video and the videoDisabled message is sent. If the stream quality improves, the videoDisableWarningLifted message is sent.
        videoDisableWarning: useCallback((e: any) => {
            //console.log('videoDisableWarning', e)
            analyticsHook.logEvent(analyticsEvt.tokbox_videoDisableWarning)
            onChangeFeedBack('videoDisableWarning')
        }, []),

        //() — This message is sent when the subscriber’s video stream starts (when there previously was no video) or resumes (after video was disabled). Check the reason parameter for the reason why the video started (or resumed).
        videoDisableWarningLifted: useCallback((e: any) => {
            //console.log('videoDisableWarningLifted', e)
            onChangeFeedBack('videoDisableWarningLifted')
        }, []),

        //(Object) — Sent periodically to report video statistics for the subscriber.
        //videoNetworkStats: (e: any) => console.log(e),
    }



    const publisherEvents = {
        // (Number) — The audio level, from 0 to 1.0. Adjust this value logarithmically for use in adjusting a user interface element, such as a volume meter. Use a moving average to smooth the data.
        //audioLevel: (e: any) => console.log(e),

        //(Object) — Sent if the publisher encounters an error. After this message is sent, the publisher can be considered fully detached from a session and may be released.
        error: onError(status),

        //(Object) — Sent if there is an error with the communication between the native publisher instance and the JS component.
        otrnError: onOTRNError,

        //(Object) — Sent when the publisher starts streaming.
        streamCreated: useCallback((e: any) => {
            onChangeFeedBack('publisherStreamCreated')
        }, []),

        //(Object) - Sent when the publisher stops streaming.
        streamDestroyed: useCallback((e: any) => {
            onChangeFeedBack('publisherStreamDestroyed')
            //console.log('publisher', e)
        }, []),
    }


    return {
        sessionEvents,
        subscriberEvents,
        publisherEvents,
        error,
        onCloseError,
        connIdParceiro
    }
}