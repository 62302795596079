import React from 'react';
import {  TouchableOpacity, Text, View, ActivityIndicator } from 'react-native';
import { Icon } from '../Icon';
import { RED } from '../../../utils/colors';


export const CircleAction = ({ onPress, loading, disabled, style = {}, size = 45, icon, color, active = true, iconStyle = {}, label, colorize }: any) => {
    return (
        <TouchableOpacity disabled={disabled} onPress={onPress} style={{alignItems: 'center', opacity: disabled ? 0.6 : 1}}>
            <View  style={{ margin: 6, backgroundColor: active ? color : 'transparent', borderRadius: 100, borderWidth: 2, borderColor: active ? color : RED, width: size, height: size, alignItems: 'center', justifyContent: 'center', ...style}} >{loading ? <ActivityIndicator size="small" color="#fff" /> : <Icon name={icon} size={28} color={active ? '#fff' : RED} style={iconStyle} />}</View>
    {Boolean(label) && <Text style={{fontSize: 10, color: colorize ? color : '#ffffff98'}}>{label}</Text>}
        </TouchableOpacity>
    )
}