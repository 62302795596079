import { Platform } from "react-native";
const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream

export const isMobile = Boolean(
    userAgent.match(/Android/i)
    || userAgent.match(/webOS/i)
    || userAgent.match(/iPhone/i)
    || userAgent.match(/iPad/i)
    || userAgent.match(/iPod/i)
    || userAgent.match(/BlackBerry/i)
    || userAgent.match(/Windows Phone/i)
);

export const getMobileOS = () => {

    if (/android/i.test(userAgent)) {
        return "android";
    }

    if (isIOS) {
        return "ios";
    }

    return undefined;
}

const isSafari = /^((?!chrome|android|crios).)*safari/i.test(navigator.userAgent);

export const isIOSNotSafari = isIOS && !isSafari