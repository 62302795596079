import React, { ReactNode } from 'react';
import { View, Text, StatusBar, Platform } from 'react-native';
import { Watermark } from '../widgets/Watermark';
import { Nav } from './nav/Nav';
import { ButtonClose } from '../widgets/ButtonClose';
import { ButtonSwitchCam } from '../widgets/ButtonSwitchCam';
import { ButtonCamera } from '../widgets/ButtonCamera';
import { ButtonMic } from '../widgets/ButtonMic';
import { Gradient } from '../utils/gradient/Gradient';
import { ChamadaControls } from '../root/Common';
import { ChamadaHook } from '../../hooks/chamada/types';
import { BLUE } from '../../utils/colors';
import { CircleAction } from '../utils/action/Action';
import { Chat } from './Chat';
import { ConnInfo } from './ConnInfo';

type ChamadaProps = {
    nativeAppButtons: any
    chamadaControls: ChamadaControls
    chamadaHook: ChamadaHook
    conferenceView: ReactNode
    hasParceiro: boolean
    isMobile: boolean
    cbEncerrar?:any
}

const raiz2_2 = Math.sqrt(2) / 2
const raio = 45 / 2
const dist = Math.abs(raio - raio * raiz2_2) - 8


export const Chamada = ({ cbEncerrar, hasParceiro, chamadaControls, chamadaHook, conferenceView, isMobile }: ChamadaProps) => {
    const {
        onChangePublishAudio,
        onChangePublishVideo,
        onChangeCameraPosition,
        publishAudio,
        publishVideo,
        cameraPosition,
        onSendMessage,
    } = chamadaControls;

    const { msgHook } = chamadaHook


    return (
        <View style={{ backgroundColor: '#afafaf', position: 'absolute', bottom: 0, top: 0, width: '100%' }}>
            <Watermark />
            <View style={{ alignItems: 'center', position: 'absolute', top: 20, width: '100%', zIndex: 3 }}>
                <View style={{ backgroundColor: '#ffffff26', padding: 8, borderRadius: 8 }}>
                    <ConnInfo hasParceiro={hasParceiro} />
                </View>
            </View>
            <Chat msgHook={msgHook} isMobile={isMobile} onSend={onSendMessage} hasParceiro={hasParceiro} />
            <View style={{ zIndex: 2 }}>{conferenceView}</View>
            <Nav>
                <ButtonClose onPress={() => {
                    cbEncerrar && cbEncerrar()
                    chamadaHook.onEncerrar()
                }} />
                <View>
                    {msgHook.pending > 0 && <View style={{ position: 'absolute', zIndex: 15, right: dist, top: dist, backgroundColor: '#43ca00', borderRadius: 12 }}><Text style={{ color: '#fff', fontSize: 12, fontWeight: 'bold', paddingVertical: 3, paddingHorizontal: 6 }}>{msgHook.pending}</Text></View>}
                    <CircleAction active={!msgHook.open} label={`${msgHook.open ? 'Fechar c' : 'C'}hat`} color={BLUE} icon="message-square" onPress={msgHook.onToggleOpen} />
                </View>
                {isMobile && <ButtonSwitchCam active={cameraPosition == 'front'} onPress={onChangeCameraPosition} />}
                <ButtonCamera active={publishVideo} onPress={onChangePublishVideo} />
                <ButtonMic active={publishAudio} onPress={onChangePublishAudio} />
            </Nav>
            <Gradient style={{ height: 120 }} />
        </View>
    )
}

