import React from 'react'
import { View } from 'react-native'

export const Gradient = ({children, style, reverse, dark}: any) => {
    const suffix = dark ? 'bd' : '60'
    const ini = reverse ? '#000000'+ suffix : 'transparent'
    const fim = reverse ? 'transparent' : '#000000' + suffix
    const position = reverse ? {top: 0} : {bottom: 0}
    return <div style={{...position, height: 60, position: 'fixed', zIndex: 10, display: 'flex', flexDirection: 'column', background: `linear-gradient(${ini}, ${fim})`, alignItems: 'center', justifyContent: 'center', left: 0, right: 0, ...style}}>
        {children}
    </div>
}
