import * as serviceWorker from './serviceWorker';
import { AppRegistry } from 'react-native'
import {Root} from 'shared/src/components/root/Root'
import font from 'react-native-vector-icons/Fonts/Feather.ttf';
const iconFontStyles = `
@font-face {
  src: url(${font});
  font-family: Feather;
}`;

const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}


document.head.appendChild(style);


AppRegistry.registerComponent('feegow-telemedicine', () => Root)
AppRegistry.runApplication('feegow-telemedicine', {
  rootTag: document.getElementById('root'),
})

serviceWorker.unregister();
