import React, { Component, ReactNode, useState } from "react";
import { StyleSheet, ViewStyle, TextInputProps, TouchableOpacity, TextInput} from "react-native";
import { FormItem, formItemInputStyle, FormItemProps } from "./FormItem";
import { Icon } from "./Icon";
import { identity } from "../../utils/functions";
import { BORDER } from "../../utils/colors";
import { Subtract } from "../../utils/types";

export type InputProps = {
    error?: string
    label?: ReactNode | string
    opt?: boolean
    name: string
    onChange?: any
    loading?: boolean
    multiline?: boolean
    rows?: number
    _ref?: any
    rootStyle?: ViewStyle
    type?: 'default' | 'number-pad' | 'decimal-pad' | 'numeric' | 'email-address' | 'phone-pad' | 'password'
    extra?: any
    disabled?: boolean
    formItemProps?: FormItemProps | {}
} & Subtract<TextInputProps, { onChange: any }>

export function Input({ rows = 3, _ref, rootStyle = {}, formItemProps = {}, loading, onFocus, disabled, type, extra, onBlur, multiline = false, placeholder, name, value, onChange, label, opt = false, error = '', onSubmitEditing, ...props }: InputProps) {
    const _onChange = onChange ? onChange(name, identity) : undefined;
    const [visiblePWD, setVisiblePWD] = useState(false)
    const multilineStyle: any = multiline ? {textAlignVertical: 'top'} : {}
    const common = {
        value: value,
        multiline: multiline,
        ref: _ref,
        placeholder,
        onFocus: onFocus,
        onBlur: onBlur,
        style: [formItemInputStyle.inputStyle, disabled ? formItemInputStyle.inputDisabled : {}, multilineStyle],
        editable: !disabled,
        numberOfLines: multiline ? rows : undefined,
    }

    function onClear() {
        _onChange('')
    }

    return (
        <FormItem
            label={label}
            error={error}
            opt={opt}
            rootStyle={{}}
            onClear={value && !disabled ? onClear : undefined}
            extra={type == 'password' ? (
                <TouchableOpacity onPress={() => setVisiblePWD(!visiblePWD)} style={{ padding: 3 }}>
                    <Icon name={visiblePWD ? 'eye' : 'eyeo'} color={BORDER} size={20} />
                </TouchableOpacity>
            ) : extra}
            disabled={disabled}
            {...formItemProps}
            input={<TextInput
                keyboardType={type == 'password' ? 'default' : type}
                secureTextEntry={type == 'password' && !visiblePWD}
                value={value}
                onChangeText={_onChange}
                placeholderTextColor="#ccc"
                onSubmitEditing={onSubmitEditing}                    
                {...common} 
                {...props}
                />}        
    />
    );
}
