import active from "./active.env"

type EnvType = {
    name: 'dev' | 'prod'
    uri: string
}

type EnvsType = {
    prod: EnvType
    dev: EnvType
}

const envs: EnvsType = {
  prod: {
    name: 'prod',
    uri: 'https://api.feegow.com.br/telemedicine-room/',
  },
  dev: {
    name: 'dev',
    // uri: 'https://arcane-peak-44898.herokuapp.com/api/dev/',
    uri: 'https://nmibtvb7of.execute-api.sa-east-1.amazonaws.com/prod/api/prod/'
  },
}


export const _env_ = envs[active] 