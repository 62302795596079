import React, { useRef } from 'react'
import { View, Text, TouchableOpacity, ScrollView, Keyboard, Platform, KeyboardAvoidingView } from "react-native"
import { BLUE, GREEN } from '../../utils/colors'
import { MsgHook, MsgType } from '../../hooks/chamada/types'
import { Input } from '../utils/Input'
import { Icon } from '../utils/Icon'
import { CircleAction } from '../utils/action/Action'
import { Modal } from '../utils/modal/modal'
import { ConnInfo } from './ConnInfo'

const marginTop = Platform.OS == 'ios' ? 12 : 0


type ChatProps = {
    isMobile: boolean,
    msgHook: MsgHook
    onSend(): any
    hasParceiro?: boolean
}

const BORDER_RADIUS = 10
const MARGIN_OTHER = 58

const itemShadow = {
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
}

const ItemMsg = (m: MsgType) => {
    const itsMe = m.from == 'me'
    const color = itsMe ? BLUE : GREEN
    const borderStyle = { borderRadius: BORDER_RADIUS, borderBottomLeftRadius: itsMe ? BORDER_RADIUS : 0, borderBottomRightRadius: itsMe ? 0 : BORDER_RADIUS }
    const marginStyle = { marginLeft: itsMe ? MARGIN_OTHER : 0, marginRight: itsMe ? 0 : MARGIN_OTHER }
    const [hh, mm] = m.time.split(':')
    return <View style={{ alignItems: itsMe ? 'flex-end' : 'flex-start', flexDirection: 'column' }}>
        <View style={{ backgroundColor: color, flexDirection: 'row', alignItems: 'flex-end', paddingVertical: 4, flex: 1, paddingHorizontal: 8, marginBottom: 4, marginHorizontal: 2, ...borderStyle, ...marginStyle, ...itemShadow }}>
            <Text style={{ color: '#fff', fontSize: 14, }}>{m.msg}</Text>
            <Text style={{ fontSize: 8, color: '#00000080', width: 25, textAlign: 'right', marginLeft: 4 }}>{hh}:{mm}</Text>
        </View>

    </View>
}

const ItemMsgBot = (m: MsgType) => {
    return <View style={{ alignItems: 'center' }}>
        <Text style={{ paddingVertical: 2, paddingHorizontal: 4, marginBottom: 2, color: '#00000060', fontSize: 12 }}>{m.msg}</Text>
    </View>
}

export const Chat = ({ isMobile, msgHook, onSend, hasParceiro }: ChatProps) => {
    const { temp, onChangeTemp, onToggleOpen, msgs, open } = msgHook
    const scrollViewRef = useRef(null)

    function onChange() {
        return (v: any) => {
            onChangeTemp(v)
        }
    }

    function goToBottom() {
        if (scrollViewRef.current != null) {
            (scrollViewRef.current as any).scrollToEnd({ animated: true })
        }
    }

    function onSendAndClear(e: any) {
        if (e.nativeEvent.key == 'Enter' && !e.shiftKey && !isMobile) {
            onSend()
            setTimeout(() => {
                onChangeTemp('')
            }, 3)
        }
    }



    const styleRoot: any = isMobile ? {
        top: 0,
        bottom: 0,
        width: '100%'
    } : {
            top: 10,
            right: 10,
            borderRadius: 8,
            width: 250,
            height: 250,
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 0,
            },
            shadowOpacity: 0.25,
            shadowRadius: 8.30,
            elevation: 13,
        }

        const main = <KeyboardAvoidingView behavior={"height"} style={{
            backgroundColor: '#ffffffde',
            position: 'absolute',
            zIndex: 12,
            ...styleRoot
        }}>
            {isMobile && <View style={{ flexDirection: 'row', padding: 12, zIndex: 99, marginTop }}>
                <TouchableOpacity onPress={onToggleOpen}><Icon name="x" size={22} /></TouchableOpacity>
            </View>}
            <ScrollView
                ref={scrollViewRef}
                onContentSizeChange={goToBottom}
                style={{ flex: 1, width: '100%', paddingTop: isMobile ? undefined : 16, marginBottom: 4, paddingHorizontal: 12 }}
            >
                {msgs.map(m => {
                    return m.from == 'bot' ? <ItemMsgBot key={m.id} {...m} /> : <ItemMsg key={m.id} {...m} />
                })}
            </ScrollView>
            <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', padding: 12 }}>
                <View style={{ flex: 1 }}>
                    {hasParceiro && <Input multiline onKeyPress={onSendAndClear} placeholder={`Digite uma mensagem${isMobile ? '' : ' e pressione Enter'}`} disabled={!hasParceiro} formItemProps={{ alt: true, rootStyle: { height: 'auto', borderRadius: 5, borderColor: '#ddd', borderWidth: 1 } }} value={temp} onChange={onChange} name="" />}
                </View>
                {hasParceiro && temp && temp.length > 0 && isMobile ? <CircleAction style={itemShadow} icon="arrow-right" onPress={onSend} color={GREEN} active /> : null}
            </View>
            {isMobile && <View style={{marginBottom: 16}}>
                <ConnInfo hasParceiro={Boolean(hasParceiro)} />
            </View>}
        </KeyboardAvoidingView>

            return isMobile ? <Modal visible={open} transparent>{main}</Modal> : (open ? main : null)
}