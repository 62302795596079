import React, {} from 'react'

import { View, Text, ScrollView, Dimensions, TouchableOpacity } from 'react-native'
import { Icon } from './Icon'
import { PRIMARY } from '../../utils/colors'

const widthMobile = Dimensions.get('window').width - 60

export const Popup = ({ open, title, body, onReset, bgColor = PRIMARY, color = '#fff', extra, isMobile = true }: any) => {

    const hasTitle = Boolean(title)

    return open ? (
        <View 
        style={{
            position: 'absolute',
            backgroundColor: '#00000090',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <View style={{
                backgroundColor: bgColor,
                borderRadius: 16,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 7,
                },
                shadowOpacity: 0.43,
                shadowRadius: 9.51,
                elevation: 15,
                paddingBottom: 8,
                width: isMobile ? widthMobile : 400
            }}>
                <View style={{ justifyContent: 'space-between', flexDirection: 'row', borderTopLeftRadius: 16, borderTopRightRadius: 16, paddingVertical: 8, alignItems: 'center' }}>
                    <View style={{flex: 1}}>{hasTitle && <Text style={{ color, paddingHorizontal: 16, fontSize: 20, textAlign: 'center', fontWeight: 'bold' }} children={title} />}</View>
                    <TouchableOpacity onPress={onReset} style={{ padding: 3, marginRight: 12 }}><Icon size={23} color={color} name="x" /></TouchableOpacity>
                </View>               
                <View>
                    <ScrollView style={{ maxHeight: 300, minHeight: 80, paddingHorizontal: 16}}>
                        <Text style={{ textAlign: 'justify', color, padding: 8, fontSize: 16 }} children={body} />
                    </ScrollView>
                </View>
                <View style={{alignItems: 'center', padding: 12}}>{extra}</View>  
            </View>
        </View>
    ) : null
}