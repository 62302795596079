import { useState, useEffect, useRef } from "react"
import { useOpenTokHandlers } from "./useOpenTokHandlers"
import { ChamadaHook, Chamada, Credentials, ChamadaMode, MsgHook, MsgType } from "./types"
import { _env_ } from "../../utils/envs"
import { analyticsEvt, AnalyticsHook } from "../useAnalytics"
import { useMsg } from "./useMsg"
import { toast } from "../../utils/toast"





const initial: Chamada = { status: 'desligada', tokenSala: null, credentials: { token: '', sessionId: '', apiKey: '' } }

export const useChamada = (isNative = false, analyticsHook: AnalyticsHook, cbEncerrar?: any): ChamadaHook => {
    //Dados da chamada atual
    const [chamada, _setChamada] = useState<Chamada>(initial)
    const [loading, setLoading] = useState(false)
    const msgHook = useMsg(false, cbEncerrar)
    const publisherRef = useRef<any>(null)
    const sessionRef = useRef<any>(null)
    const subscriberRef = useRef<any>(null)

    useEffect(() => {
        return () => onEncerrar()
    }, [])



    //handlers do tokbox
    const otHandlers = useOpenTokHandlers(chamada.status, onEncerrar, msgHook, analyticsHook)

    /**
     * FUNÇÕES DE ALTERAÇÃO DO STATUS DA CHAMADA
     */
    function _encerrar() {
        toast('Chamada encerrada')
        _setChamada({ status: 'encerrada', tokenSala: null, credentials: { token: '', sessionId: '', apiKey: '' } })
    }


    function _ativar(credentials: Credentials) {
        _setChamada((old) => ({ ...old, status: 'ativa', credentials }))
    }

    function _atualizar(status: 'naoencontrada') {        
        _setChamada((old) => ({ ...old, status }))
    }    


    function onReset() {
        msgHook.onReset()
        _setChamada(initial)
    }    

    function onEncerrar() {
        msgHook.onReset()
        _encerrar()
    }

    async function onEntrar(credentials: Credentials) {
        msgHook.onReset()
        _ativar(credentials)
    }

    function onAceitar() {
        return Promise.resolve()
    }

    async function getCredentials(entity: string, session: string){
        if(session){
            analyticsHook.logUser(session)
            try{
                setLoading(true)
                const _entity = entity ? `${entity}/` : ''
                const url = `${_env_.uri}credentials/${_entity}${session}`
                const req = await fetch(url)
                const credentials = await req.json()                
                if(req.status == 200){
                    analyticsHook.logEvent(analyticsEvt.sessao_iniciada)
                    onEntrar(credentials)
                }else{
                    throw new Error(JSON.stringify(credentials))
                }                
            }catch(e){
                analyticsHook.logEvent(analyticsEvt.sessao_nao_encontrada)
                _atualizar('naoencontrada')
                toast('Sessão não encontrada')
            }finally{
                setLoading(false)
            }
        }else{
            analyticsHook.logEvent(analyticsEvt.sessao_vazia)
            _atualizar('naoencontrada')            
            toast(isNative ? 'Acesse o link fornecido pela clínica' : 'Sessão não encontrada')
        }
    }


    return {
        onEntrar,
        chamada,
        onEncerrar,
        onReset,
        onAceitar,
        otHandlers,
        getCredentials,
        loading,
        msgHook,
        publisherRef,
        sessionRef,
        subscriberRef,
    }
}

