import React from 'react'
import { ChamadaControls } from '../root/Common';
import { ChamadaHook } from '../../hooks/chamada/types';
import { OTSession, OTPublisher, OTSubscriber, OTStreams, OT } from 'opentok-react';
export const OpenTok = ({chamadaControls, chamadaHook}: {chamadaControls: ChamadaControls, chamadaHook: ChamadaHook}) => {

    const {
        publishAudio, 
        publishVideo, 
    } = chamadaControls;
    const {
        publisherRef,
        sessionRef,
        subscriberRef,
        chamada,
        otHandlers
    } = chamadaHook
    const {token, sessionId, apiKey} = chamada.credentials
    const {sessionEvents, publisherEvents, subscriberEvents} = otHandlers

    return chamadaHook.chamada.status == 'ativa' ? (
        <OTSession
            ref={sessionRef}
            onError={sessionEvents.error}
            apiKey={apiKey}
            sessionId={sessionId}
            token={token}
            eventHandlers={sessionEvents}>
            <OTPublisher
                ref={publisherRef}
                properties={{
                    showControls: false,
                    publishAudio,
                    publishVideo,
                    audioFallbackEnabled: false
                }}
                onError={publisherEvents.error}
                eventHandlers={publisherEvents}
            />
            <OTStreams>
                <OTSubscriber
                    ref={subscriberRef}
                    properties={{ showControls: false }}
                    eventHandlers={subscriberEvents}
                    onError={subscriberEvents.error}
                />
            </OTStreams>
        </OTSession>
    ) : null
}
