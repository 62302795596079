import React from 'react'
import { View, Text, Image, Platform, TouchableOpacity } from 'react-native'

export const Watermark = () => {
    return <TouchableOpacity style={{ position: 'absolute', opacity: 0.3, left: 10, top: Platform.OS == 'ios' ? 20 : 6, zIndex: 10, }}>
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={{ fontSize: 10, color: '#fff', fontWeight: 'bold' }}>By </Text>
        <Image style={{ width: 57, height: 15 }} source={require('../../img/logo.gif')} />
    </View>
    <View>
        <Text style={{ fontSize: 8, color: '#fff', fontWeight: 'bold', marginLeft: 16 }}>v1.0.12</Text>
    </View>
</TouchableOpacity>
}