import React, {MutableRefObject, ReactNode, Fragment} from 'react'
import { PreJoin } from '../prejoin/PreJoin'
import { ChamadaHook } from '../../hooks/chamada/types'
import { Chamada } from '../chamada/Chamada'
import { Popup } from '../utils/Popup'
import { View, Text } from 'react-native'

export type ChamadaControls = {
    publishAudio: boolean
    publishVideo: boolean
    cameraPosition: 'front' | 'back'
    hasParceiro: boolean
    onJoin():void
    onChangePublishAudio():void
    onChangePublishVideo():void
    onChangeCameraPosition():void
    onSendMessage():void
}

type RootCommonProps = {
    chamadaHook: ChamadaHook
    chamadaControls: ChamadaControls
    nativeAppButtons?: ReactNode
    preJoinCameraView: ReactNode
    conferenceView: ReactNode
    dialogHint?: ReactNode
    hasParceiro: boolean
    isMobile: boolean
    cbEncerrar?: any
}

export const Common = ({chamadaHook, cbEncerrar, hasParceiro, isMobile, dialogHint, nativeAppButtons, chamadaControls, preJoinCameraView, conferenceView}: RootCommonProps) => {

   return <Fragment>
        {chamadaHook.chamada.status == 'ativa' ? <Chamada 
        cbEncerrar={cbEncerrar}
        isMobile={isMobile}
        hasParceiro={hasParceiro}
    nativeAppButtons={nativeAppButtons} 
    chamadaControls={chamadaControls} 
    chamadaHook={chamadaHook}
    conferenceView={conferenceView}
    /> : <PreJoin 
        nativeAppButtons={nativeAppButtons} 
        chamadaControls={chamadaControls} 
        chamadaHook={chamadaHook}
        cameraView={preJoinCameraView}
    />}
    <Popup 
        isMobile={isMobile}
        color="#000"
        bgColor="#fff"
        body={<Fragment>
           <View>
           <Text style={{textAlign: 'center', color: '#000', fontSize: 22}}>{chamadaHook.otHandlers.error.msg}{dialogHint ? ' Siga os passos abaixo e tente novamente. ' : ''}</Text>
           </View>
            {dialogHint}
        </Fragment>}
        onReset={chamadaHook.otHandlers.onCloseError}
        open={Boolean(chamadaHook.otHandlers.error.msg)}
    />
    </Fragment>
}