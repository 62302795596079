type ColorsType = 'ORANGE' | 'BLUE' | 'PURPLE' | 'GREEN' | 'RED' | 'MAGENTA' | 'GEEKBLUE' | "VOLCANO" | "GREY" | "PRIMARY" | "HIGHLIGHT"

const VALUE = '#00000090'

const DANGER = '#f5222d';
const SUCCESS = '#87d068';  
const BASE = '#f8f8f8';
const INFO = '#0072ff';
const WARN = '#faad14';

const VALIDATION = '#f5222d';
const BORDER = '#ccc'; 

const PRIMARY = '#0069D7';
const HIGHLIGHT = '#F9B000';


const ORANGE= '#fa8c16';
const ORANGE_LIGHT= '#ffd591';
const ORANGE_LIGHTER= '#fff7e6';

const BLUE= '#1890ff';
const BLUE_LIGHT= '#91d5ff';
const BLUE_LIGHTER= '#e6f7ff';  

const PURPLE= '#722ed1';
const PURPLE_LIGHT= '#d3adf7';
const PURPLE_LIGHTER= '#f9f0ff';    

const GREEN= '#35a000';
const GREEN_LIGHT= '#b7eb8f';
const GREEN_LIGHTER= '#e4fbd9';  

const RED= '#f5222d';
const RED_LIGHT= '#ffa39e';
const RED_LIGHTER= '#fff1f0';    

const MAGENTA= '#eb2f96';
const MAGENTA_LIGHT= '#ffadd2';
const MAGENTA_LIGHTER= '#fff0f6';      

const GEEKBLUE= '#2f54eb';
const GEEKBLUE_LIGHT= '#adc6ff';
const GEEKBLUE_LIGHTER= '#f0f5ff';  

const VOLCANO = '#fa541c';
const VOLCANO_LIGHT = '#ffbb96';
const VOLCANO_LIGHTER = '#fff2e8';  

const GREY = '#000000a6';
const GREY_LIGHT = '#d9d9d9';
const GREY_LIGHTER = '#fafafa';


const FEEGOW_LIGHT = '#19b9d4'
const FEEGOW_DARK = "#082b3c"

export {
    ColorsType,
    VALUE,
    DANGER,
    SUCCESS,
    BASE,
    INFO,
    WARN,
    VALIDATION,
    BORDER,
    ORANGE,
    ORANGE_LIGHT,
    ORANGE_LIGHTER,
    BLUE,
    BLUE_LIGHT,
    BLUE_LIGHTER,
    PURPLE,
    PURPLE_LIGHT,
    PURPLE_LIGHTER,
    GREEN,
    GREEN_LIGHT,
    GREEN_LIGHTER,
    RED,
    RED_LIGHT,
    RED_LIGHTER,
    MAGENTA,
    MAGENTA_LIGHT,
    MAGENTA_LIGHTER,
    GEEKBLUE,
    GEEKBLUE_LIGHT,
    GEEKBLUE_LIGHTER,
    VOLCANO,
    VOLCANO_LIGHT,
    VOLCANO_LIGHTER,
    GREY,
    GREY_LIGHT,
    GREY_LIGHTER,
    PRIMARY,
    HIGHLIGHT,  
    FEEGOW_LIGHT,
    FEEGOW_DARK 
}