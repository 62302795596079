import React, { Fragment, useRef, useEffect, useCallback, useState } from 'react';
import { Common, ChamadaControls } from './Common';
import { useChamada } from '../../hooks/chamada/useChamada';
import { Linking, Platform, View, TouchableOpacity, Text, ActivityIndicator } from 'react-native';
import { isMobile, getMobileOS, isIOSNotSafari } from '../../utils/device';
import { analyticsEvt, useAnalytics } from '../../hooks/useAnalytics';
import { canSend } from '../../hooks/chamada/useMsg';
import { CircleAction } from '../utils/action/Action';
import { GREEN, RED, PRIMARY } from '../../utils/colors';
import { Popup } from '../utils/Popup';
import Webcam from "react-webcam";
import { OpenTok } from '../opentok/OpenTok.web';
import { toast } from '../../utils/toast';

const urlParams = new URLSearchParams(window.location.search);
const url_redirect = urlParams.get('url_redirect');
const session = urlParams.get('session');
const _entity = urlParams.get('entity')
const entity = _entity ? _entity : '';
const autoJoin = Boolean(urlParams.get('autoJoin'));

const os = getMobileOS()
const icon = os == 'android' || os == 'ios' ? 'smartphone' : undefined
const showNativeAppButtons = Boolean(Boolean(urlParams.get('showDownload')) && icon && isMobile)

const PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.feegowtelemedicine'
const APP_STORE = 'https://apps.apple.com/us/app/consulta-on-line/id1515867576?l=pt&ls=1'

const analytics = (window as any).firebase.analytics()


const PreJoinCameraView = ({ publishVideo }: { publishVideo: boolean }) => publishVideo ? <div id="video-externo"><Webcam mirrored audio={false} /></div> : null
const isIframe = window.self !== window.top
export const Root = () => {
    const analyticsHook = useAnalytics(analytics, session ?? '')
    const chamadaHook = useChamada(false, analyticsHook, cbEncerrar)
    const [publishAudio, setPublishAudio] = useState(true)
    const [publishVideo, setPublishVideo] = useState(true)
    const [cameraPosition, setCameraPosition] = useState<'front' | 'back'>('front')
    const [openDownloadAlert, setOpenDownloadAlert] = useState(true)
    const hasParceiro = Boolean(chamadaHook.otHandlers.connIdParceiro)

    /**
     * Função executada ao encerrar ou ao receber o evento de que o parceiro encerrou
     */
    function cbEncerrar(sendMessage = true) {
        sendMessage && _onSendMessage('%%ENCERRAR%%') //Emite mensagem de encerramento para o parceiro
        if (isIframe) { //Se é médico, emite mensagem para o pai e para o parceiro
            window.parent.postMessage({ isIframe }, "*");
        } else if (url_redirect) { //Se é paciente e tem url_redirect, redireciona
            window.location.href = url_redirect
        }
    }

    useEffect(() => {
        if (autoJoin) {
            onJoin()
        }

        if(isIframe){
            function onParentMessage(e: any){
                if(e && e.data == '%%CB_ENCERRAR%%'){
                    cbEncerrar(true)
                }
            }
            window.addEventListener("message", onParentMessage, false);

            return () => {
                window.removeEventListener("message", onParentMessage, false);
            }
        }
    }, [])

    function onJoin() {
        chamadaHook.getCredentials(entity, session)
    }

    function onChangePublishAudio() {
        setPublishAudio((v) => !v)
    }
    function onChangePublishVideo() {
        setPublishVideo((v) => !v)
    }
    function onChangeCameraPosition() {
        if (chamadaHook.publisherRef.current) {
            chamadaHook.publisherRef.current.getPublisher().cycleVideo()
            setCameraPosition(v => v == 'back' ? 'front' : 'back')
        }
    }

    async function onOpenAppFromBrowser() {
        analyticsHook.logEvent(analyticsEvt.abrir_nativo_via_browser)
        await Linking.openURL(`feegowtm://feegowtm/${entity}/${session}`);
    }

    async function onDownloadApp() {
        analyticsHook.logEvent(analyticsEvt.acessar_store)
        await Linking.openURL(os == 'android' ? PLAY_STORE : APP_STORE)
    }

    function onCloseDownloadAlert() {
        setOpenDownloadAlert(false)
    }

    function onSendMessage() {
        if (canSend(chamadaHook.msgHook.temp)) {
            _onSendMessage(chamadaHook.msgHook.temp, (e: any) => {
                if (e) {
                    toast('Não foi possível enviar a mensagem')
                }
            })
        }
    }

    function _onSendMessage(data: string, cbError?: any) {
        if (hasParceiro) {
            const to = chamadaHook.subscriberRef.current.getSubscriber().stream.connection
            chamadaHook.sessionRef.current.sessionHelper.session.signal({
                data,
                to
            }, cbError)
            chamadaHook.msgHook.onSend()
        }
    }

    const nativeAppButtons = showNativeAppButtons ? <Fragment>
        <CircleAction label="Baixar" active={true} color={GREEN} icon='download' onPress={onDownloadApp} />
        <CircleAction label="Abrir App" active={true} color={GREEN} icon={icon} onPress={onOpenAppFromBrowser} />
    </Fragment> : null


    const chamadaControls = {
        publishAudio,
        publishVideo,
        cameraPosition,
        hasParceiro,
        onJoin,
        onChangePublishAudio,
        onChangePublishVideo,
        onChangeCameraPosition,
        onSendMessage,
    }

    const showHint = chamadaHook.otHandlers.error.type == 'OT_USER_MEDIA_ACCESS_DENIED'

    return <Fragment>
        {isIOSNotSafari && <TouchableOpacity onPress={onDownloadApp} style={{ backgroundColor: RED, zIndex: 20 }}><Text style={{ color: '#fff', padding: 8, fontSize: 11 }}>A vídeo conferência não é compatível com o Chrome no iOS. Por favor, acesse a plataforma pelo Safari ou baixe nosso App.</Text></TouchableOpacity>}
        <Popup
            open={showNativeAppButtons && isMobile && openDownloadAlert}
            title="Consulta é coisa séria!"
            body="Baixe o nosso app para que você tenha uma melhor experiência durante o seu atendimento!"
            extra={<View style={{ flexDirection: 'row' }}>{nativeAppButtons}</View>}
            onReset={onCloseDownloadAlert}
            isMobile={isMobile}
        />
        <Common
            cbEncerrar={cbEncerrar}
            isMobile={isMobile}
            hasParceiro={hasParceiro}
            dialogHint={showHint && os != undefined ? <img style={{ width: '100%', height: 'auto', marginTop: 16 }} src={os == 'ios' ? require('../../img/acesso_ios.png') : require('../../img/acesso_android.png')} /> : undefined}
            chamadaHook={chamadaHook}
            chamadaControls={chamadaControls}
            nativeAppButtons={nativeAppButtons}
            preJoinCameraView={<PreJoinCameraView publishVideo={publishVideo} />}
            conferenceView={<OpenTok chamadaControls={chamadaControls} chamadaHook={chamadaHook} />}
        />
        {(autoJoin && chamadaHook.chamada.status === 'desligada') && <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 30, alignItems: 'center', justifyContent: 'center', backgroundColor: '#afafaf' }}><ActivityIndicator size="large" color={PRIMARY} /></View>}
    </Fragment>

}