import {useState, useEffect} from 'react'
import { MsgHook, MsgType, From } from './types'
import { uuidv4 } from '../../utils/numbers'

export const canSend = (msg: string) => msg.replace(/\s+/g,'').trim().length > 0

export const useMsg = (initalOpen = false, cbEncerrar?: any): MsgHook => {
    const [msgs, setMsgs] = useState<Array<MsgType>>([])
    const [temp, setTemp] = useState('')
    const [open, setOpen] = useState(initalOpen)
    const [pending, setPending] = useState(0)

    useEffect(() => {
        if(!open && msgs.length > 0){
            setPending((p) => p+1)
        }
    }, [msgs.length])

    function onAddMsg(msg: string, from: From){
        const time = new Date().toLocaleTimeString('pt-br')
        const id = uuidv4()
        if(msg != '%%ENCERRAR%%'){
            setMsgs((old) => ([...old,{msg, from, time, id}]))
        }else{
            cbEncerrar && cbEncerrar(from == 'me')
        }
    }

    function onChangeTemp(v: string){
        setTemp(v)
    }

    function onSend(){
        if(canSend(temp)){
            onAddMsg(temp, 'me')
        }
        onChangeTemp('')
    }

    function onReset(){
        setMsgs(() => [])
        setTemp('')
        setOpen(initalOpen)
        setPending(0)
    }

    function onToggleOpen(){
        setOpen((open) => !open)
        setPending(0)
    }
    


    return {
        msgs,
        onAddMsg,  
        onReset,
        onSend,
        temp, 
        onChangeTemp,
        open,
        onToggleOpen,
        pending  
    }
}