import React, {ReactNode, useEffect} from 'react'
import { ScrollView, Text, Dimensions, View, ActivityIndicator, Platform } from 'react-native'
import { Gradient } from '../utils/gradient/Gradient';
import { Watermark } from '../widgets/Watermark';
import { ButtonCamera } from '../widgets/ButtonCamera';
import { ButtonMic } from '../widgets/ButtonMic';
import { ButtonJoin } from '../widgets/ButtonJoin';
import { toast } from '../../utils/toast';
import { PreJoinProps } from './common';
const {height} = Dimensions.get('window')


export const PreJoin = ({chamadaHook, chamadaControls, nativeAppButtons, cameraView}: PreJoinProps) => {
    const {publishVideo, publishAudio, onChangePublishVideo, onChangePublishAudio, onJoin} = chamadaControls
    const {loading} = chamadaHook
    

    return <ScrollView style={{flex: 1}}>
    {cameraView}
    <Gradient reverse dark={false}>
        <Watermark />
        {loading ? <View style={{ alignItems: 'flex-end', width: '100%', paddingHorizontal: 16, marginTop: Platform.OS == 'ios' ? 26 : 16 }}><ActivityIndicator color="#fff" /></View>  : null}
    </Gradient>
    <Gradient dark style={{ height: height / 4 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 16 }}>
            {nativeAppButtons}
            <ButtonCamera active={publishVideo} onPress={onChangePublishVideo} />
            <ButtonMic active={publishAudio} onPress={onChangePublishAudio} />
            <ButtonJoin disabled={loading}  onPress={onJoin} />
            </View>
    </Gradient>
</ScrollView>
}