import React, { Component, ReactNode } from "react";
import { StyleSheet, View, Text, ViewStyle, TextStyle, TouchableOpacity } from "react-native";
import { VALIDATION, ColorsType } from "../../utils/colors";
import * as cores from '../../utils/colors'
import { Icon } from "./Icon";

type LabelProps = {
    id: string,
    children?: any
    color?: string
    align?: 'center' | 'left' | 'right' | 'justify'
    bold?: boolean
    size?: number
    width?: number
    numberOfLines?: number
}

export type FormItemProps = {
    error?: string
    label?: ReactNode | string
    opt?: boolean
    rootStyle?: ViewStyle
    extra?: any
    input: ReactNode
    bordered?: boolean
    color?: ColorsType
    labelStyle?: TextStyle
    errorStyle?: TextStyle
    onClear?: any
    alt?:boolean
    inputContainerStyle?: ViewStyle
    disabled?: boolean
}

export const Label = ({ id, size = 10, color = cores.PRIMARY, align = 'center', bold = true, width, numberOfLines = undefined }: LabelProps) => {
    return <Text numberOfLines={numberOfLines} style={{ fontSize: size, maxWidth: width, color, fontWeight: bold ? 'bold' : '400', textAlign: align, textTransform: 'uppercase' }}>{id}</Text>
}

/**
 * Estilos pré-definidos
 */
export const defautlFormItemProps = {
    borderOnInput :{alt: true, rootStyle: {backgroundColor: '#fff', padding: 0, flexDirection: 'column', height: 64, alignItems: 'flex-start'}, labelStyle: {marginLeft: 12}, inputContainerStyle: {borderRadius: 32, borderColor: cores.BORDER, paddingHorizontal: 12, borderWidth: 2, width: '100%'}}
}

export function FormItem({ rootStyle = {}, inputContainerStyle={}, disabled, bordered=false, label, alt, error, opt, input, color, errorStyle = {}, labelStyle = {}, extra, onClear }: FormItemProps) {
    const bg = color ? {
        backgroundColor: cores[`${color}_LIGHTER` as cores.ColorsType],
        borderColor: cores[`${color}_LIGHT` as cores.ColorsType],
        borderWidth: 1,
        borderBottomWidth: 1,
        padding: 16,
        paddingTop: 0,
        marginTop: 16
    } : {};
    
    const _label = typeof label == 'string' ? <Label align="left" color={error ? cores.VALIDATION : (disabled? cores.BORDER : cores.PRIMARY)} id={label} /> : label
    const __label = <Text style={[labelStyle,  alt ? styles.labelAlt : styles.label]}>{_label}{opt ? '' : <Text style={[styles.req]}>*</Text>}</Text>

    return (
        <View>
            <View style={[bordered ? borderStyle : {},{marginBottom: 2,backgroundColor: "transparent"}]}>
                <View style={[styles.container, error ? styles.containerError : {}, bg, alt ? styles.altContainer : {}, rootStyle]}>
                    {Boolean(label) ? __label : <View style={{ marginTop: 6 }}></View>}
                    <View style={[{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-between' }, inputContainerStyle]}>
                        <View style={{ flex: 1 }}>
                            {input}
                        </View>
                        {(Boolean(extra) || Boolean(onClear)) && (
                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {Boolean(onClear) && (
                                    <TouchableOpacity onPress={onClear} style={{padding: 3, paddingRight: 0}}>
                                        <Icon name="x-circle" color={cores.BORDER} size={20} />
                                    </TouchableOpacity>
                                )}
                                {extra}
                            </View>
                        )}
                    </View>
                </View>
            </View>
            {Boolean(error) && <Text style={[styles.error, errorStyle]}>{error}</Text>}
        </View>
    );
}

export const borderStyle = {borderWidth: 2, padding: 14, marginBottom: 8, borderColor: cores.BORDER, borderRadius: 25};

export const inputStyle: any = {
    minHeight: 31,
    color: cores.VALUE,
    alignSelf: "stretch",
    paddingVertical: 2,
    fontSize: 14,
    lineHeight: 16,
    
}

export const formItemInputStyle = StyleSheet.create({
    inputStyle,
    inputDisabled: {
        color: cores.BORDER
    },
});

const styles = StyleSheet.create({
    altContainer: {
        borderBottomWidth: 0,
        backgroundColor: '#fff',
        borderRadius: 20,
        paddingRight: 10,
        paddingLeft: 16,
        paddingVertical: 6,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 36       
    },
    altInnerContainer: {

    },
    container: {
        backgroundColor: "transparent",
        borderColor: cores.BORDER,
        borderBottomWidth: 1
    },
    containerError: {
        borderColor: VALIDATION,
    },
    label: {
        width: '100%',
        paddingTop: 16,
        fontSize: 12,
        // fontFamily: "roboto-regular",
        textAlign: "left",
        flex: 1
    },
    labelAlt: {
        paddingTop: 0,
        marginRight: 10,
    },
    error: {
        paddingRight: 15,
        color: VALIDATION,
    },
    labelError: {
        color: VALIDATION,
        fontWeight: '600'
    },
    req: {
        color: VALIDATION
    },
});
