import { _env_ } from "../utils/envs"

export type AnalyticsType = {
    logEvent(name: string, params?: any): Promise<any>
    setUserId(id: string): Promise<any>
    setUserProperties(params: any): Promise<any>
}

export const analyticsEvt = {
    /**
     * @param error
     */
    erro_tokbox_previsto: 'erro_tokbox_previsto',
    erro_tokbox_previsto_notado: 'erro_tokbox_previsto_notado',
    /**
     * @param error
     */
    tokbox_otrnerror: 'tokbox_otrnerror',
    tokbox_videoDisabled: 'tokbox_videoDisabled',
    tokbox_videoDisableWarning: 'tokbox_videoDisabledWarning',
    sessao_nao_encontrada: 'sessao_nao_encontrada',
    sessao_vazia: 'sessao_vazia',
    sessao_iniciada: 'sessao_iniciada',
    abrir_nativo_via_browser: 'abrir_nativo_via_browser',
    acessar_store: 'acessar_store',
}

export type AnalyticsHook = {
    logEvent(name: string, params?: any):void
    logUser(token: string):void
}

export const useAnalytics = (props: AnalyticsType, session: string): AnalyticsHook => {

    async function logEvent(name: string, params: any = {}) {
        let _params = {...params, session}
        
        if(params && params.error){
            _params = {..._params, session_error: `${session}_${params.error}`}
        }

        try {
            await props.logEvent(name, _params)
            console.log('LOG_EVENT', name, _params)
        } catch (e) {
            console.log('LOG_EVENT_FAIL', name, e)
        }
    }

    async function logUser(token: string) {
        try {
            await props.setUserId(token)
            await props.setUserProperties({env: _env_.name, session})
            console.log('LOG_USER', token)
        }catch(e){
            console.log('LOG_USER_FAIL', e)
        }
    }

    return {
        logEvent,
        logUser
    }
}